<template>
    <complete-wrapper
        step="geo_targeting"
        compute-width>
        <template v-for="([platform, { locations }]) in Object.entries(geoTargeting)">
            <location
                v-for="(location, index) in locations"
                :key="`${platform}-${index}`"
                :platform="platform"
                :location="location" />
        </template>
    </complete-wrapper>
</template>

<script>
import CompleteWrapper from '../../components/CompleteWrapper';
import Location from './Location';
import { mapState } from 'vuex';

export default {
    components: {
        Location,
        CompleteWrapper
    },
    computed: {
        ...mapState({
            geoTargeting: (state) => state.adDeployment.geo_targeting.platform_specific,
        }),
    }
};
</script>