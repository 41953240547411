<template>
    <div
        :class="{
            'first-selected': currentIndex === 0
        }">
        <div class="platform-tabs">
            <button
                v-for="platform in formattedPlatforms"
                :key="platform.key"
                class="platform-tab"
                :class="{
                    'current': currentPlatform === platform.key
                }"
                @click="currentPlatform = platform.key">
                <span
                    class="platform-tab-status"
                    :class="{
                        'complete': isPlatformComplete(platform)
                    }">
                    {{ isPlatformComplete(platform) ? 'Complete' : 'Incomplete' }}
                </span>
                <span class="platform-tab-label">
                    <icon
                        :name="platform.icon_name"
                        size="20"
                        :color="currentPlatform === platform.key ? colors.blueDark : undefined" />
                    {{ platform.display_name }}
                </span>
            </button>
        </div>
        <div class="platform-map">
            <div class="platform-map-header">
                <span>Select location(s):</span>
                <styled-button
                    v-if="currentIndex < (platforms.length - 1)"
                    outline
                    small
                    @click="handleNextChannel">
                    Next Channel
                </styled-button>
            </div>
            <keep-alive>
                <geo-selector
                    :key="currentPlatform"
                    :value="value[currentPlatform].locations"
                    :types="currentPlatformConfig.types"
                    :min-radius="currentPlatformConfig.minRadius"
                    :max-radius="currentPlatformConfig.maxRadius"
                    :min-height="minHeight"
                    :default-center="defaultCenter"
                    @input="handleGeoInput(currentPlatform, $event)" />
            </keep-alive>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import StyledButton from '@/components/globals/StyledButton';
import GeoSelector from '@/components/globals/GeoSelector';
import { getChannelByPlatformName } from '@/components/onboarding/channel-setup/channels';
import colors from '@/helpers/colors';
import { mapState } from 'vuex';

export default {
    components: {
        Icon,
        GeoSelector,
        StyledButton,
    },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        platformConfig: {
            type: Object,
            default: () => {},
        },
        minHeight: {
            type: String,
            default: '450px'
        },
        defaultCenter: {
            type: Object,
            default: undefined
        },
    },
    data() {
        return {
            currentPlatform: null,
            error: '',
            colors
        };
    },
    computed: {
        ...mapState({
            platforms: state => state.adDeployment.platforms,
        }),
        currentIndex() {
            return this.platforms.indexOf(this.currentPlatform);
        },
        currentPlatformConfig() {
            return this.platformConfig[this.currentPlatform];
        },
        formattedPlatforms() {
            return this.platforms.map(platform => (
                getChannelByPlatformName(platform)
            ));
        },
        isPlatformComplete: ({ value }) => ({ key }) => {
            if (value) {
                return value[key]?.locations?.length;
            }
        },
    },
    created() {
        this.currentPlatform = this.platforms[0];
    },
    methods: {
        handleGeoInput(platform, locations) {
            this.value[platform].locations = locations;
            this.$emit('input', this.value);
        },
        handleNextChannel() {
            const currentIndex = this.platforms.indexOf(this.currentPlatform);
            // We don't need to guard since the button's presence does that
            const nextPlatform = this.platforms[currentIndex + 1];
            this.currentPlatform = nextPlatform;
        },
    }

};
</script>

<style lang="scss" scoped>
.platform-tabs {
    display: flex;
}
.platform-tab {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $loblolly;
    border-bottom: none;
    margin-right: 10px;
    bottom: -4px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 90px;
    width: 100px;
    overflow: hidden;
    &.current {
        background-color: #E6F6FD;
        color: $blue-dark;
        z-index: 10;
        height: 87px;
        top: 4px;
        bottom: -1px;
    }
}

.platform-tab-status {
    background-color: $warning-500;
    text-transform: uppercase;
    color: $white;
    width: 100%;
    font-size: 10px;
    padding: 4px 0;
    &.complete {
        background-color: $blue-dark;
    }
}

.platform-tab-label {
    padding: 0 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.platform-map {
    position: relative;
    z-index: 5;
    padding: 15px;
    background-color: #E6F6FD;
    border: 1px solid $loblolly;
    border-radius: 6px;
    .first-selected & {
        border-top-left-radius: 0;
    }
}

.platform-map-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    button {
        background: $white;
        text-transform: uppercase;
        font-weight: 700;
    }
}
</style>
