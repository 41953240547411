<template>
    <v-expansion-panel
        :value="controlPanel"
        expand
        class="expansion-container">
        <v-expansion-panel-content
            @input="handleClick">
            <template #actions>
                {{ buttonText }}
            </template>
            <template
                v-if="dmaPlatforms.length"
                #header>
                <icon
                    class="mr-2"
                    name="indicator-warning"
                    color="#FF3D00"
                    size="18" />
                <div>{{ dmaPlatformsName }} doesn't support pin drop selections so we have estimated the nearest DMAs.</div>
            </template>
            <v-card>
                <table>
                    <thead>
                        <tr>
                            <th
                                v-for="(header, index) in headers"
                                :key="index"
                                class="centered">
                                {{ header }}
                            </th>
                            <th>Location(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(platform, index) in selectedPlatforms"
                            :key="index">
                            <td class="w10 centered">
                                <icon
                                    size="18"
                                    :color="platformIconColor(platformType(platform))"
                                    :name="platform" />
                            </td>
                            <td class="pindrop w20 centered">
                                {{ formattedType[platformType(platform)] }}
                            </td>
                            <td class="w20 centered">
                                <action-button
                                    size="18"
                                    :color="hideLocation[platformType(platform)] ? 'gray' : 'blue'"
                                    :icon="hideLocation[platformType(platform)] ? 'hide' : 'eye'"
                                    @click="changeHideLocation(platformType(platform))" />
                            </td>
                            <td class="location w40">
                                {{ platformLocation(platformType(platform)) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import Icon from '@/components/globals/Icon';
import EventBus from '@/event-bus';
import { mapState } from 'vuex';
import ActionButton from '@/components/globals/ActionButton';
import { GEO, DMA, POSTAL_CODE, CUSTOM } from '@/components/ad-deployment/store/constants';
import { PLATFORM_PINTEREST, PLATFORM_TIKTOK } from '@/helpers/globals';

export default {
    name: 'PlatformsExpansionInfo',
    components: {
        Icon,
        ActionButton
    },
    props: {
        locations: {
            type: Array,
            required: true
        },
        platformConfig: {
            type: Object,
            default: () => ({})
        },
        pinDropLabel: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            headers: ['Channel', 'Type', 'Show/Hide'],
            expandPanel: false,
            isPanelExpanded: false,
            formattedType: {
                [GEO]: 'PIN DROP',
                [DMA]: 'DMA',
                [POSTAL_CODE]: 'POSTAL CODE',
                [CUSTOM]: 'CUSTOM',
            },
            hideLocation: {
                [GEO]: false,
                [DMA]: false,
                [POSTAL_CODE]: false,
                [CUSTOM]: false,
            },
            prettyPlatformName: {
                [PLATFORM_TIKTOK]: 'TikTok',
                [PLATFORM_PINTEREST]: 'Pinterest',
            }
        };
    },
    computed: {
        ...mapState({
            selectedPlatforms: state => state.adDeployment.platforms,
            customMilePinterest: state => state.adDeployment.custom_mile_pinterest,
        }),
        controlPanel() {
            return [this.expandPanel];
        },
        dmaPlatforms() {
            return Object.keys(this.platformConfig).filter(key => (
                this.platformConfig[key].types.includes(DMA)
            ));
        },
        buttonText() {
            return this.expandPanel || this.isPanelExpanded ? 'Hide details' : 'Show details';
        },
        dmaPlatformsName() {
            return this.dmaPlatforms.map(platform => this.prettyPlatformName[platform]).join(', ');
        }
    },
    created() {
        EventBus.$on('expand-platform-info', () => {
            this.expandPanel = !this.expandPanel;
        });
    },
    methods: {
        changeHideLocation(platformType) {
            this.hideLocation[platformType] = !this.hideLocation[platformType];

            EventBus.$emit('hide-location', this.hideLocation);
        },
        platformType(platform) {
            if (this.customMilePinterest && platform === PLATFORM_PINTEREST) {
                return CUSTOM;
            }

            return this.platformConfig[platform]?.types[0];
        },
        platformLocation(platformType) {
            if ([GEO, CUSTOM].includes(platformType)) {
                return this.pinDropLabel;
            }

            return this.locations.filter(location => (
                location.type === platformType
            )).map(location => (
                location.name
            )).join(', ');
        },
        platformIconColor(platformType) {
            return [DMA, CUSTOM].includes(platformType) ? 'red' : 'gray';
        },
        handleClick() {
            this.isPanelExpanded = !this.isPanelExpanded;
        }
    }
};
</script>

<styled lang="scss">
.expansion-container {
    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        th {
            height: 40px;
        }

        td {
            height: 50px;
        }

        tr, th, td {
            border-bottom: 1px solid #D9D9D9;
            padding-left: 50px;
        }

        th {
            color: $black;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
        }

        .w5 {
            width: 5%;
        }

        .w10 {
            width: 10%;
        }

        .w20 {
            width: 20%;
        }

        .w40 {
            width: 45%;
        }

        .centered {
            text-align: center;
        }

        .pindrop {
            opacity: 1;
            color: rgba(74,74,74,1);
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            letter-spacing: 0px;
            text-transform: uppercase;
        }

        .location {
            font-size: 14px;
            color: $blue-primary;
            font-style: italic;
            margin-left: 15px;
        }
    }

    .v-expansion-panel__body {
        border-top: 1px solid #D9D9D9;
    }

    .v-expansion-panel__header {
        background: #FFCFC0;
    }

    .v-expansion-panel__header__icon {
        color: $blue-btn;
        text-decoration: underline;
        margin-left: 3px;
    }
}
