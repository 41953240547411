<template>
    <gmap-marker
        ref="marker"
        v-bind="markerProps"
        :draggable="draggable"
        @dragend="handleDragEnd">
        <gmap-circle
            ref="circle"
            :center="value.coordinates"
            :radius="radius"
            :options="{
                fillColor: value?.color ?? colors.blue,
                strokeOpacity: 0.5,
                strokeWeight: 0,
            }" />
    </gmap-marker>
</template>

<script>
import GmapCircle from 'vue2-google-maps/src/components/circle';
import GmapMarker from 'vue2-google-maps/src/components/marker';
import { gmapApi } from 'vue2-google-maps';
import colors from '@/helpers/colors';
import { MILES_PER_METER } from '@/helpers/globals';

export default {
    components: {
        GmapCircle,
        GmapMarker
    },
    props: {
        /**
         * {
         *   type: string,
         *   coordinates: {
         *     lat: number,
         *     lng: number
         *   },
         *   radius: number,
         *   paths: array,
         * }
         */
        value: {
            type: Object,
            required: true
        },
        draggable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            colors
        };
    },
    computed: {
        markerProps() {
            return {
                icon: this.google && {
                    path: this.google.maps.SymbolPath.CIRCLE,
                    fillColor: colors.blue,
                    fillOpacity: 1,
                    strokeWeight: 0,
                    scale: 6
                },
                position: {
                    lat: this.value?.coordinates?.lat,
                    lng: this.value?.coordinates?.lng
                }
            };
        },
        radius() {
            return this.value.radius / MILES_PER_METER;
        },
        google: gmapApi
    },
    async mounted() {
        await this.$refs.circle.$circlePromise;

        // Append the ref to the location for external use
        this.$emit('input', {
            ...this.value,
            $circleRef: this.$refs.circle
        });
    },
    methods: {
        handleDragEnd(event) {
            this.$emit('input', {
                ...this.value,
                coordinates: this.getCoordinates(event),
            });
        },
        /**
         * Retrieves coordinates from the map events
         */
        getCoordinates(event) {
            return {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            };
        },
    }
};
</script>
