<template>
    <div>
        <gmap-map
            ref="map"
            class="map-container"
            :center="center"
            :zoom="zoom"
            :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: true,
                styles: lightMapStyles
            }"
            :style="{
                width: '100%',
                height: '100%',
                minHeight: minHeight
            }"
            @click="handleMapClick">
            <template v-for="(location, index) in pinPointLocations">
                <location-marker
                    :key="index"
                    :value="location"
                    :draggable="isNotSimpleGeoAndIsSinglePoint"
                    @input="handleLocationUpdate(index, $event)" />
            </template>
        </gmap-map>
    </div>
</template>
<script>
import LocationMarker from '@/components/globals/GeoSelector/LocationMarker';
import GmapMap from 'vue2-google-maps/src/components/map';
import { gmapApi } from 'vue2-google-maps';
import colors from '@/helpers/colors';
import { mapState } from 'vuex';
import { light as lightMapStyles } from '@/helpers/mapStyles';
import EventBus from '@/event-bus';
import { GEO, DMA, POSTAL_CODE, CUSTOM } from '@/components/ad-deployment/store/constants';

export default {
    components: {
        GmapMap,
        LocationMarker,
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        minHeight: {
            type: String,
            default: '450px'
        },
        defaultCenter: {
            type: Object,
            required: true,
        },
        types: {
            type: Array,
            default: () => [GEO, DMA, POSTAL_CODE]
        },
    },
    data() {
        return {
            usDefaultCenter: {
                lat: 39.8283,
                lng: -98.5795
            },
            zoom: 9,
            colors,
            lightMapStyles,
            activeGeoJsonItems: [],
            hideLocation: {
                [GEO]: false,
                [DMA]: false,
                [POSTAL_CODE]: false,
                [CUSTOM]: false,
            },
            mapColors: {
                [POSTAL_CODE]: colors.purple,
                [DMA]: colors.green,
            }
        };
    },
    computed: {
        ...mapState({
            isSimpleGeo: (state) => state.adDeployment.is_simple_geo,
        }),
        center() {
            if (!this.defaultCenter?.lat || !this.defaultCenter?.lng) {
                return this.usDefaultCenter;
            }

            return this.defaultCenter;
        },
        pinPointLocations() {
            if (this.hideLocation[GEO] && this.hideLocation[CUSTOM]) {
                return [];
            }

            if (this.hideLocation[GEO]) {
                return this.value.filter(location => (
                    location.type === GEO && location.isCustom
                ));
            }

            if (this.hideLocation[CUSTOM]) {
                return this.value.filter(location => (
                    location.type === GEO && !location.isCustom
                ));
            }

            return this.value.filter(location => (
                location.type === GEO
            ));
        },
        google: gmapApi,
        isNotSimpleGeoAndIsSinglePoint() {
            return !this.isSimpleGeo && this.pinPointLocations.length === 1;
        },
    },
    watch: {
        async value(value) {
            await this.handleNewValues(value);
        }
    },
    created() {
        EventBus.$on('hide-location', ({ dma, geo, custom }) => {
            this.hideLocation = { dma, geo, custom };
        });
    },
    methods: {
        async handleNewValues(value) {
            await this.$refs?.map?.$mapPromise;

            this.syncGeoJsonLocations(value);

            if (this.value.length) {
                // Wait for the markers to be mounted. This is necessary
                // for testing.
                await this.$nextTick();
                await this.fitBounds();
            }
        },
        syncGeoJsonLocations(newLocations) {
            this.removeGeoJsonLocation();

            for (const location of newLocations) {
                if (location.type === GEO) {
                    continue;
                }

                this.addGeoJsonLocation(location);
            }
        },
        handleMapClick(event) {
            if (this.isSimpleGeo) {
                return;
            }

            if (this.types.includes(GEO)) {
                const newValue = [...this.value];

                newValue.push({
                    type: GEO,
                    coordinates: this.getCoordinates(event),
                    geometry: this.getCoordinates(event),
                    radius: 20
                });

                this.$emit('input', newValue);
            }
        },
        addGeoJsonLocation(location) {
            const { $mapObject: map } = this.$refs.map;
            const $mapFeatures = map?.data?.addGeoJson(this.formatGeoJson(location));

            for (const feature of $mapFeatures) {
                map.data.overrideStyle(feature, {
                    fillColor: this.mapColors[location.type],
                    strokeOpacity: 0.5,
                    strokeWeight: 0,
                });
            }

            this.activeGeoJsonItems.push({
                location,
                $mapFeatures
            });
        },
        formatGeoJson(area) {
            return {
                type: 'Feature',
                properties: {
                    type: 'Polygon'
                },
                geometry: area.geometry ?? area.geoJson.geometry
            };
        },
        removeGeoJsonLocation() {
            this.activeGeoJsonItems.forEach(({ $mapFeatures }) => {
                if ($mapFeatures) {
                    for (const feature of $mapFeatures) {
                        this.$refs.map.$mapObject.data.remove(feature);
                    }
                }
            });

            this.activeGeoJsonItems = [];
        },
        getCoordinates(event) {
            return {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            };
        },
        async fitBounds() {
            await this.$refs.map.$mapPromise;
            const { $mapObject: map } = this.$refs.map;
            const bounds = new this.google.maps.LatLngBounds();

            // Add the bounds of the geoJson features
            map.data.forEach(feature => {
                feature.getGeometry().forEachLatLng(latLng => {
                    bounds.extend(latLng);
                });
            });

            // Add the bounds to the pin drop locations
            this.pinPointLocations.forEach(location => {
                bounds.extend(location.coordinates);
            });

            // If we only have a single geo point, create two fictional points to zoom out a little bit
            if (this.isNotSimpleGeoAndIsSinglePoint || this.isSimpleGeo) {
                const extendPoint1 = new this.google.maps.LatLng(bounds.getNorthEast().lat() + 0.6, bounds.getNorthEast().lng() + 0.6);
                const extendPoint2 = new this.google.maps.LatLng(bounds.getNorthEast().lat() - 0.6, bounds.getNorthEast().lng() - 0.6);

                bounds.extend(extendPoint1);
                bounds.extend(extendPoint2);
            }

            map.fitBounds(bounds);
        },
        async handleLocationUpdate(index, event) {
            this.$emit('location-update', {
                index,
                location: event
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.map-container {
    border: 1px solid $gray;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
}
</style>
