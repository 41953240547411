<template>
    <div>
        <map-locations
            v-model="locations"
            :types="types"
            :default-center="center" />
        <div>
            <location-entry
                v-for="(location, index) in locations"
                :key="index"
                :value="location"
                :min-radius="minRadius"
                :max-radius="maxRadius"
                @input="handleLocationUpdate(index, $event)"
                @remove="removeLocation(index)" />
            <div class="add-location">
                <template v-if="types.includes(GEO)">
                    <img src="/img/map-location.svg">
                    <span class="add-location-prompt">
                        Click the map to add locations
                    </span>
                    <span
                        class="add-location-or">
                        or
                    </span>
                </template>
                <v-autocomplete
                    ref="select"
                    v-model="searchPlaceholder"
                    :items="searchResults || []"
                    item-text="name"
                    placeholder="Search for locations..."
                    return-object
                    :loading="loadingSearch"
                    hide-no-data
                    no-filter
                    @keyup="handleAddressSearch($event)"
                    @change="handleSelection" />
            </div>
        </div>
    </div>
</template>

<script>
import LocationEntry from './LocationEntry';
import { gmapApi } from 'vue2-google-maps';
import { debounce } from 'lodash';
import MapLocations from '@/components/ad-deployment/steps/GeoTargeting/MapLocations';
import { mapState } from 'vuex';
import { GEO, DMA, POSTAL_CODE } from '@/components/ad-deployment/store/constants.js';

export default {
    components: {
        LocationEntry,
        MapLocations
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        minRadius: {
            type: [String, Number],
            default: 15
        },
        maxRadius: {
            type: [String, Number],
            default: 50
        },
        minHeight: {
            type: String,
            default: '450px'
        },
        types: {
            type: Array,
            default: () => [GEO, DMA, POSTAL_CODE]
        },
    },
    data() {
        return {
            GEO,
            DMA,
            POSTAL_CODE,
            searchPlaceholder: '',
            locations: [],
            loadingSearch: false,
            searchResults: [],
            center: {
                lat: 37.09024,
                lng: -95.712891
            }
        };
    },
    computed: {
        ...mapState({
            triggerMapRefresh: (state) => state.adDeployment.trigger_map_refresh
        }),
        // TODO: Whenever we start having more than one supported type per platform, we should go back to this and refactor
        searchType() {
            switch (true) {
                case this.types.includes(GEO):
                    return GEO;
                case this.types.includes(DMA):
                    return DMA;
                case this.types.includes(POSTAL_CODE):
                    return POSTAL_CODE;
                default:
                    return null;
            }
        },
        google: gmapApi
    },
    mounted() {
        this.initLocations();
    },
    methods: {
        async initLocations() {
            for (const location of this.value) {
                if (location.type === GEO) {
                    this.locations.push({
                        ...location,
                        radius: this.triggerMapRefresh ? 20 : location.radius,
                    });
                } else {
                    this.locations.push(location);
                }

                // Wait after each to ensure the code below works
                // this is necessary because the locations add
                // in their object references when mounted
                await this.$nextTick();
            }
        },
        async handleSelection(input) {
            await this.$nextTick();
            this.searchPlaceholder = '';

            // Prevent duplicate locations
            if (this.locations.some(location => location.id === input.id)) {
                this.$flash('Location already added', 'error');
                return;
            }

            this.locations.push(input);
            this.emitInput();
        },
        handleLocationUpdate(index, location) {
            this.$set(this.locations, index, location);
            this.emitInput();
        },
        handleAddressSearch: debounce(async function(e) {
            try {
                this.loadingSearch = true;

                if (this.types.includes(GEO)) {
                    this.searchResults = await this.$apiRepository.searchAddress(e.target.value);
                }

                if (this.types.includes(DMA)) {
                    this.searchResults = await this.$apiRepository.searchDmaByName(e.target.value);
                }

                if (this.types.includes(POSTAL_CODE)) {
                    this.searchResults = await this.$apiRepository.searchPostalCodeByName(e.target.value);
                }
            } catch(err) {
                console.log(err);
            } finally {
                this.loadingSearch = false;
            }
        }, 300),
        removeLocation(index) {
            this.locations.splice(index, 1);
            this.emitInput();
        },
        emitInput() {
            this.$emit('input', this.locations);
        },
    }
};
</script>

<style lang="scss" scoped>
.map-container {
    border: 1px solid $gray;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
}

.add-location {
    padding: 10px;
    display: flex;
    align-items: center;
    border: 1px dashed $loblolly;
    background: $white;
    border-radius: 6px;
    img {
        width: 45px;
        height: auto;
    }
}

.add-location-prompt {
    font-size: 16px;
    margin-left: 10px;
}
.add-location-or {
    margin: 0 20px;
}
</style>

<style lang="scss">
.add-location {
    .v-input.v-text-field {
        margin: 0;
        padding: 0;
        & > .v-input__control > .v-input__slot {
            margin: 0;
            border-radius: 4px;
            border: 1px solid $loblolly;
            padding: 5px 10px;
            &::after, &::before {
                content: none;
            }
            input {
                &::placeholder {
                    color: $gray-dark;
                    font-size: 16px;
                    font-style: italic;
                }
            }
        }
        .v-input__append-inner {
            display: none;
        }
        .v-messages {
            min-height: 0;
        }
    }
}
</style>
