var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('gmap-map',{ref:"map",staticClass:"map-container",style:({
            width: '100%',
            height: '100%',
            minHeight: _vm.minHeight
        }),attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: true,
            styles: _vm.lightMapStyles
        }},on:{"click":_vm.handleMapClick}},[_vm._l((_vm.pinPointLocations),function(location,index){return [_c('location-marker',{key:index,attrs:{"value":location,"draggable":_vm.isNotSimpleGeoAndIsSinglePoint},on:{"input":function($event){return _vm.handleLocationUpdate(index, $event)}}})]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }