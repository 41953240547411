<template>
    <div class="location">
        <div class="location__main">
            <span class="location__title">
                <icon
                    :name="platform === 'facebook' ? 'meta' : platform"
                    color="#8F9EA6"
                    size="20" />
                <span>{{ typeLabel }}</span>
            </span>
            <span class="location__name">
                {{ label }}
            </span>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { GEO } from '@/components/ad-deployment/store/constants';

export default {
    components: {
        Icon
    },
    props: {
        platform: {
            type: String,
            required: true
        },
        location: {
            type: Object,
            required: true
        }
    },
    computed: {
        typeLabel() {
            const map = {
                geo: 'Pin Drop',
                dma: 'DMA'
            };

            return map[this.location.type];
        },
        label() {

            if (this.location.type === GEO && this.location.address?.city) {
                const { city, region, regionShort, postalCode, postal_code, countryShort, country } = this.location.address;
                return `Near ${city}, ${regionShort ?? region} ${postalCode ?? postal_code} (${countryShort, country})`;
            }

            if (this.location.type === GEO) {
                return `
                    ${this.$formatNumber(this.location.coordinates.lat)},
                    ${this.$formatNumber(this.location.coordinates.lng)}
                `;
            }

            return this.location.name;
        }
    }
};
</script>

<style lang="scss" scoped>

.location {
    white-space: nowrap;
    display: inline-block;
    background: white;
    padding: 10px 15px;
    margin: 6px;
    border: 1px solid #DFDFE0;
    &__bottom{
        margin-top: 12px;
        position: relative;
        padding-left: 30px;
        &:after{
            content: '';
            position: absolute;
            width: 20px;
            height: 1px;
            background: #999999;
            top: 10px;
            left: 5px;
        }
        &:before{
            content: '';
            position: absolute;
            width: 1px;
            height: 15px;
            background: #999999;
            top: -5px;
            left: 5px;
        }
    }
    &__title{
        font-weight: 700;
        margin-right: 15px;
        text-transform: uppercase;
        span {
            margin-left: 15px;
        }
    }
    &__name{
        color: #03A2EA;
        font-style: italic;
    }
}
</style>