var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'first-selected': _vm.currentIndex === 0
    }},[_c('div',{staticClass:"platform-tabs"},_vm._l((_vm.formattedPlatforms),function(platform){return _c('button',{key:platform.key,staticClass:"platform-tab",class:{
                'current': _vm.currentPlatform === platform.key
            },on:{"click":function($event){_vm.currentPlatform = platform.key}}},[_c('span',{staticClass:"platform-tab-status",class:{
                    'complete': _vm.isPlatformComplete(platform)
                }},[_vm._v(" "+_vm._s(_vm.isPlatformComplete(platform) ? 'Complete' : 'Incomplete')+" ")]),_c('span',{staticClass:"platform-tab-label"},[_c('icon',{attrs:{"name":platform.icon_name,"size":"20","color":_vm.currentPlatform === platform.key ? _vm.colors.blueDark : undefined}}),_vm._v(" "+_vm._s(platform.display_name)+" ")],1)])}),0),_c('div',{staticClass:"platform-map"},[_c('div',{staticClass:"platform-map-header"},[_c('span',[_vm._v("Select location(s):")]),(_vm.currentIndex < (_vm.platforms.length - 1))?_c('styled-button',{attrs:{"outline":"","small":""},on:{"click":_vm.handleNextChannel}},[_vm._v(" Next Channel ")]):_vm._e()],1),_c('keep-alive',[_c('geo-selector',{key:_vm.currentPlatform,attrs:{"value":_vm.value[_vm.currentPlatform].locations,"types":_vm.currentPlatformConfig.types,"min-radius":_vm.currentPlatformConfig.minRadius,"max-radius":_vm.currentPlatformConfig.maxRadius,"min-height":_vm.minHeight,"default-center":_vm.defaultCenter},on:{"input":function($event){return _vm.handleGeoInput(_vm.currentPlatform, $event)}}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }