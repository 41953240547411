<template>
    <div class="slider">
        <div class="min">
            {{ min }}
        </div>
        <input
            class="slider-input"
            type="range"
            :min="min"
            :max="max"
            :value="value"
            @input="handleChange"
            @change="handleChange">
        <div class="max">
            {{ max }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number,String],
            required: true
        },
        min: {
            type: [Number,String],
            default: 0
        },
        max: {
            type: [Number,String],
            default: 100
        },
    },
    methods: {
        handleChange(e) {
            this.$emit('input',
                e.target.value ? parseInt(e.target.value) : ''
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.slider {
    display: flex;
    align-items: center;
}
.slider-input {
  -webkit-appearance: none;
  width: 100%;
  margin: 0 15px;
  height: 7px;
  border-radius: 8px;
  background: lighten($loblolly, 10%);
  outline: none;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: $blue-btn;
    box-shadow: 1px 1px 4px rgba($black, 0.2);
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: $blue-btn;
    cursor: pointer;
  }
}
</style>