<template>
    <div class="location-entry-container">
        <div class="location-entry">
            <div class="location-entry-label">
                <span
                    class="location-entry-type"
                    v-html="divideLabel(typeLabel)" />
                <span class="location-entry-location">
                    <styled-loader
                        v-if="loadingReverseGeo"
                        size="15" />
                    <template v-else>
                        <icon
                            v-show="isSimpleGeo"
                            color="#00A2EA"
                            size="18"
                            name="building" />
                        {{ label }}
                    </template>
                </span>
            </div>

            <div class="location-entry-controls">
                <div
                    v-if="value?.type === GEO"
                    class="location-entry-slider">
                    <styled-slider
                        :min="minRadius"
                        :max="maxRadius"
                        :value="radius"
                        @input="handleRadiusInput" />
                    <span class="location-entry-slider-value">
                        {{ radius }}mi
                    </span>
                </div>
                <action-button
                    v-if="isSimpleGeo"
                    class="ml-2"
                    size="14"
                    color="black"
                    icon="chevron-down"
                    @click="handleExpandEvent" />
                <action-button
                    v-else
                    class="location-entry-remove"
                    icon="indicator-error"
                    @click="$emit('remove')" />
            </div>
        </div>
        <platforms-expansion-info
            class="platform-expansion-info"
            :locations="locations"
            :platform-config="platformConfig"
            :pin-drop-label="label" />
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import StyledSlider from '@/components/globals/StyledSlider';
import StyledLoader from '@/components/globals/StyledLoader';
import { formatToBuyerBridgeLocation, formatGoogleAddress } from '@/helpers/addressFormatters.js';
import numeral from 'numeral';
import getNumberFormats from '@/helpers/numberFormats';
import { mapState } from 'vuex';
import Icon from '@/components/globals/Icon';
import PlatformsExpansionInfo from '@/components/ad-deployment/steps/GeoTargeting/PlatformsExpansionInfo';
import EventBus from '@/event-bus';
import { DMA, GEO } from '@/components/ad-deployment/store/constants';

const numberFormats = getNumberFormats();

export default {
    components: {
        ActionButton,
        StyledSlider,
        StyledLoader,
        Icon,
        PlatformsExpansionInfo,
    },
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        minRadius: {
            type: [String, Number],
            default: 15
        },
        maxRadius: {
            type: [String, Number],
            default: 50
        },
        platformConfig: {
            type: Object,
            default: () => {}
        },
        locations: {
            type: Array,
            default: () => []
        },
        showAddress: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            GEO,
            loadingReverseGeo: false,
            googleAddress: null,
            defaultRadius: 20,
        };
    },
    computed: {
        ...mapState({
            isSimpleGeo: state => state.adDeployment.is_simple_geo
        }),
        typeLabel() {
            const map = {
                geo: this.isSimpleGeo ? 'Account Location' : 'Pin Drop',
                dma: DMA
            };

            return map[this.value?.type];
        },
        label() {
            if (this.showAddress) {
                // We're using a short name here to make the string literal fill in a single line
                // Also, we're not using destructuring because we were getting some console erros due to the properties being undefined
                const g = this.googleAddress;

                return `${g?.streetNumber} ${g?.street}, ${g?.city}, ${g?.regionShort ?? g?.region} ${g?.postalCode}`;
            }

            if (this.value?.type === GEO && this.googleAddress?.city) {
                const { city, region, regionShort, postalCode, countryShort } = this.googleAddress;
                return `Near ${city}, ${regionShort ?? region} ${postalCode} (${countryShort})`;
            }


            if (this.value?.type === GEO) {
                return `
                    ${numeral(this.value?.coordinates.lat).format(numberFormats.decimal)},
                    ${numeral(this.value?.coordinates.lng).format(numberFormats.decimal)}
                `;
            }

            return this.value?.name;
        },
        radius() {
            return this.value?.radius;
        }
    },
    watch: {
        value(current, previous) {
            if (
                this.value?.type === GEO
                && (
                    current?.coordinates.lat !== previous?.coordinates.lat
                    || current?.coordinates.lng !== previous?.coordinates.lng
                )
            ) {
                this.getReverseGeoCode();
            }
        }
    },
    created() {
        if (this.value?.type === GEO) {
            this.getReverseGeoCode();
        }
    },
    methods: {
        handleExpandEvent() {
            EventBus.$emit('expand-platform-info');
        },
        handleRadiusInput(radius) {
            this.value.radius = radius;
            this.$emit('input', {
                ...this.value,
                radius,
            });
        },
        async getReverseGeoCode() {
            try {
                this.loadingReverseGeo = true;

                const response = await this.$http.get('/google/maps/geocode/json', {
                    latlng: `${this.value?.coordinates.lat},${this.value?.coordinates.lng}`
                });

                if (!response.data.status == 'OK') {
                    throw 'Google returned bad status code';
                }

                const {
                    address_components: addressComponents
                } = response.data?.results?.[0];

                if (addressComponents) {
                    this.googleAddress = formatGoogleAddress(addressComponents);

                    // Append the address for external use
                    this.value.radius = this.defaultRadius;
                    this.$emit('input', formatToBuyerBridgeLocation({
                        ...this.value,
                        ...this.googleAddress
                    }, GEO));
                }

            } catch(error) {
                console.error(error);
            } finally {
                this.loadingReverseGeo = false;
            }
        },
        divideLabel(label) {
            if (label === 'Account Location') {
                return '<span class="divided-text">Account<br>Location</span>';
            }

            return label;
        }
    }
};
</script>

<style lang="scss" scoped>
.location-entry-container {
    border: 1px solid $loblolly;
    margin-bottom: 10px;

    .location-entry {
        padding: 5px;
        display: flex;
        align-items: center;
        background: $white;
        border-radius: 6px;
    }
}

.location-entry-type {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
}

.location-entry-label {
    display: flex;
    align-items: center;
    width: 55%;
    padding-left: 5px;
}

.location-entry-location {
    color: $blue-primary;
    font-style: italic;
    margin-left: 15px;
    padding-right: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.location-entry-controls {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 45%;
}

.location-entry-slider {
    display: flex;
    align-items: center;
    flex: 1;
    .slider {
        flex: 1;
    }
}

.location-entry-slider-value {
    color: $blue-primary;
    font-weight: 700;
    font-size: 16px;
    margin-left: 20px;
}

.location-entry-remove {
    margin-left: 20px;
}
</style>
<style>
.location-entry-type .divided-text {
    font-size: 14px;
    line-height: 18px;
    display: inline-block;
}
</style>