
/**
 * Retrieves the effective width of an HTML element
 * with wrapped children.  This is primarily used in cases
 * where we want to position something along the width of the
 * elements where the natural HTML will expand the container
 * and `width: fit-content` will constrain it.  Hopefully CSS
 * will solve this one day!  ...or it has and I can't find it!
 *
 * @param {HTMLElement} elem
 */
const getEffectiveElementWidth = (elem = {}) => {
    const {
        children = [],
        offsetWidth: wrapperWidth = 0
    } = elem;

    const widths = [...children].map((child) => {
        let { width } = child.getBoundingClientRect();
        const style = window.getComputedStyle(child);
        return (
            width
            + parseFloat(style.marginLeft)
            + parseFloat(style.marginRight)
        );
    });

    const rows = [];
    let rowNumber = 0;

    const startNewRow = (childWidth) => {
        rows.push({
            items: [ childWidth ],
            width: childWidth
        });
    };

    for (const childWidth of widths) {

        // If this is a new row add the current item to it
        // and move to the next item
        if (!rows[rowNumber]) {
            startNewRow(childWidth);
            continue;
        }

        // From here we're dealing with an existing row
        const row = rows[rowNumber];

        // Determine what the new row's width would be to see
        // if it will fit
        const newRowWidth = row.width + childWidth;

        // If the new row width exceeds the wrapper width
        // it will break so start a new row
        if (newRowWidth > wrapperWidth) {
            startNewRow(childWidth);
            rowNumber++;
            continue;
        }

        // Otherwise put it in the current row
        row.items.push(childWidth);
        row.width += childWidth;
    }

    const largestRowWidth = Math.max(
        ...rows.map(row => row.width)
    );

    return largestRowWidth;
}

export default getEffectiveElementWidth;