<template>
    <step-wrapper
        :index="index"
        :active="active"
        :valid="valid"
        :step="name"
        title="Where do you want your ads to run?"
        :help-link="helpLink"
        description="
            Select the geographic areas you want to run
            your ads in by using the map below:
        ">
        <styled-dialog v-model="dialogOpen">
            <div class="text-xs-center">
                <h2 class="mb-4">
                    Are you Sure?
                </h2>
                <p>
                    When navigating from Customized to Simple map mode, all location customization done will be changed
                    back to the defaults.
                    Click Continue below if you wish to change map modes or select cancel to stay in the current mode.
                </p>
                <styled-button
                    class="mt-4"
                    @click="changeTargeting">
                    CONTINUE
                </styled-button>
            </div>
            <template #actions>
                <a
                    href="#"
                    class="red--text"
                    @click.prevent="closeDialog">
                    Cancel
                </a>
            </template>
        </styled-dialog>
        <complete v-if="!active" />
        <template v-else>
            <div class="geo-targeting-selector mt-3 mb-1">
                <div
                    class="controller-container mr-4"
                    @click.prevent="changeMapType(true)">
                    <styled-radio
                        class="mr-1"
                        :value="isSimpleGeo"
                        :input-value="true"
                        label="Simple geo targeting"
                        name="simpleGeo"
                        dot-style
                        inline />
                    <ad-deployment-tooltip
                        content-path="adDeployment.geoTargeting.simple" />
                </div>
                <div
                    class="controller-container"
                    @click.prevent="changeMapType(false)">
                    <styled-radio
                        class="mr-1"
                        :value="isSimpleGeo"
                        :input-value="false"
                        label="Customize"
                        name="simpleGeo"
                        :disabled="customMilePinterest"
                        dot-style
                        inline />
                    <ad-deployment-tooltip
                        content-path="adDeployment.geoTargeting.custom" />
                </div>
            </div>
            <div
                v-if="shouldShowCustomMilePinterest"
                class="pinterest-switcher">
                <v-switch
                    v-model="customMilePinterest"
                    class="mr-1"
                    color="light-blue" />
                <div class="mile-selector">
                    <span>Use custom</span>
                    <v-select
                        v-model="customMilePinterestValue"
                        :disabled="!customMilePinterest"
                        :items="customRanges"
                        class="styled-field ml-2 mr-2" />
                    <span>mile audience for Pinterest</span>
                </div>
                <ad-deployment-tooltip
                    content-path="adDeployment.geoTargeting.customMilePinterest" />
            </div>
            <simple-geo-selector
                v-if="isSimpleGeo"
                :key="customMilePinterest + customMilePinterestValue"
                v-model="geoTargeting"
                class="mt-2"
                :platform-config="activePlatformsConfig" />
            <platform-geo-selector
                v-else
                v-model="geoTargeting"
                min-height="360px"
                :default-center="dealerCoordinates"
                :platform-config="activePlatformsConfig" />
            <div class="button-sad-holder">
                <styled-button
                    small
                    :disabled="!valid"
                    @click="saveLocationsAndGoToNextStep">
                    <span>Continue</span>
                </styled-button>
            </div>
        </template>
    </step-wrapper>
</template>

<script>
import StepWrapper from '../../components/StepWrapper';
import PlatformGeoSelector from '@/components/globals/PlatformGeoSelector';
import StyledButton from '@/components/globals/StyledButton';
import StyledRadio from '@/components/globals/StyledRadio';
import StyledDialog from '@/components/globals/StyledDialog';
import Complete from './Complete';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
    PLATFORM_FACEBOOK,
    PLATFORM_PINTEREST,
    PLATFORM_SNAPCHAT,
    PLATFORM_TIKTOK
} from '@/helpers/globals';
import stepProps from '../stepProps';
import SimpleGeoSelector from './SimpleGeoSelector';
import EventBus from '@/event-bus';
import { GEO, DMA, POSTAL_CODE } from '@/components/ad-deployment/store/constants';
import AdDeploymentTooltip from '@/components/ad-deployment/components/AdDeploymentTooltip.vue';

export default {
    name: 'GeoTargeting',
    components: {
        AdDeploymentTooltip,
        PlatformGeoSelector,
        StyledButton,
        StyledRadio,
        StyledDialog,
        StepWrapper,
        Complete,
        SimpleGeoSelector,
    },
    props: stepProps,
    data() {
        return {
            dealer: null,
            dialogOpen: false,
            helpLink: 'https://support.buyerbridge.com/knowledge/how-to-ad-launcher#custom-geotargeting',
            customRanges: [15, 30, 50],
        };
    },
    computed: {
        ...mapState({
            specialAdsCategory: state => state.adDeployment.campaign_selection.all_platforms.ad_category,
            selectedChannels: state => state.adDeployment.platforms,
            deploymentDealerId: state => state.adDeployment.dealer_id,
        }),
        isSimpleGeo: {
            get() {
                return this.$store.state.adDeployment.is_simple_geo;
            },
            set(value) {
                this.$store.commit('adDeployment/setIsSimpleGeo', value);
            }
        },
        geoTargeting: {
            get() {
                return this.$store.state.adDeployment.geo_targeting.platform_specific;
            },
            set(value) {
                this.$store.commit('adDeployment/setGeoTargeting', value);
            }
        },
        customMilePinterest: {
            get() {
                return this.$store.state.adDeployment.custom_mile_pinterest;
            },
            set(value) {
                this.setAdDeploymentValue({
                    key: 'custom_mile_pinterest',
                    value
                });
            }
        },
        customMilePinterestValue: {
            get() {
                return this.$store.state.adDeployment.custom_mile_pinterest_value;
            },
            set(value) {
                this.setAdDeploymentValue({
                    key: 'custom_mile_pinterest_value',
                    value
                });
            }
        },
        dealerCoordinates() {
            return {
                lat: parseFloat(this.dealer?.latitude),
                lng: parseFloat(this.dealer?.longitude)
            };
        },
        activePlatformsConfig() {
            return Object.fromEntries(
                Object.entries(this.defaultPlatformsConfig).filter(([key]) =>
                    this.selectedChannels.includes(key)
                )
            );
        },
        pinterestConfig() {
            if (this.isStaticDeployment()) {
                return { types: [POSTAL_CODE] };
            }

            if (this.shouldShowCustomMilePinterest && this.customMilePinterest) {
                return {
                    types: [GEO],
                    minRadius: 1,
                    maxRadius: 30
                };
            }

            return { types: [DMA] };
        },
        defaultPlatformsConfig() {
            return {
                [PLATFORM_FACEBOOK]: {
                    types: [GEO],
                    minRadius: this.specialAdsCategory === 'none' ? 5 : 15,
                    maxRadius: 50
                },
                [PLATFORM_SNAPCHAT]: {
                    types: [GEO],
                    minRadius: 1,
                    maxRadius: 62
                },
                [PLATFORM_TIKTOK]: {
                    types: [DMA]
                },
                [PLATFORM_PINTEREST]: this.pinterestConfig
            };
        },
        isUsDealer() {
            return this.dealer?.country_code === 'US';
        },
        isPinterestSelected() {
            return this.selectedChannels.includes(PLATFORM_PINTEREST);
        },
        shouldShowCustomMilePinterest() {
            return this.isUsDealer && this.isPinterestSelected && !this.isStaticDeployment();
        }
    },
    async created() {
        const { data } = await this.$apiRepository.getDealer(this.deploymentDealerId);

        this.dealer = data.data;

        this.customMilePinterest = this.isPinterestSelected;
    },
    methods: {
        ...mapActions({
            goToNextStep: 'adDeployment/goToNextStep'
        }),
        ...mapGetters('adDeployment', [
            'isStaticDeployment'
        ]),
        ...mapMutations({
            setAdDeploymentValue: 'adDeployment/setAdDeploymentValue'
        }),
        saveLocationsAndGoToNextStep() {
            this.$store.commit('adDeployment/setTriggerMapRefresh', false);
            if (this.isSimpleGeo) {
                EventBus.$emit('save-locations');
            }
            this.goToNextStep();
        },
        changeMapType(value) {
            this.targetingToChange = value;

            if (!this.isSimpleGeo) {
                this.dialogOpen = true;
            } else {
                EventBus.$emit('save-locations');
                this.isSimpleGeo = !this.isSimpleGeo;
            }
        },
        changeTargeting() {
            this.isSimpleGeo = this.targetingToChange;

            EventBus.$emit('save-locations');
            this.$store.commit('adDeployment/setTriggerMapRefresh', true);

            this.closeDialog();
        },
        closeDialog() {
            this.dialogOpen = false;
            this.targetingToChange = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.geo-targeting-selector {
    display: flex;
    flex-direction: row;
}

.completed {
    display: inline-flex;
    align-items: center;
}

.single-geo-targeting {
    margin-right: 30px;
}

.single-geo-targeting-wrapper {
    margin: -6px;
}

.single-name {
    color: #03A2EA;
    font-style: italic;
    display: inline-block;
    margin-top: 5px;
}

.geo-targeting-title {
    margin-bottom: 15px;
    font-weight: 700;
    position: absolute;
    top: 15px;
    left: 15px;
}

.controller-container {
    display: flex;
}
</style>

<style lang="scss">
.geo-targeting-selector .styled-radio.inline {
    margin-bottom: 0;
    font-size: 16px;
}

.pinterest-switcher {
    display: flex;

    .ad-deployment-tooltip {
        margin-top: 0;
    }

    .v-label {
        color: #4e555b;
    }

    .v-input {
        flex: none
    }

    .mile-selector {
        display: flex;
        align-items: center;
    }
}

.controller-container {
    display: flex;
}
</style>
