<template>
    <div class="complete-wrapper">
        <div ref="inner">
            <slot />
        </div>
        <edit-button
            :style="{
                left: `calc(${effectiveWidth} + 10px)`
            }"
            :step="step" />
    </div>
</template>

<script>
import getEffectiveElementWidth from '@/helpers/getEffectiveElementWidth';
import EditButton from './EditButton';
import debounce from 'debounce';

export default {
    components: {
        EditButton
    },
    props: {
        step: {
            type: String,
            required: true
        },
        computeWidth: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            effectiveWidth: '100%'
        };
    },
    mounted() {
        if (this.computeWidth) {
            this.onResize = debounce(() => {
                this.setEffectiveWidth();
            }, 50);
            window.addEventListener('resize', this.onResize.bind(this));
            this.setEffectiveWidth();
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        /**
         * There doesn't seem to be another obvious way to
         * determine the actual displayed width of wrapped
         * inline-block items.  I'm sure there's an easier
         * way but I can't find it!
         */
        setEffectiveWidth() {
            const width = getEffectiveElementWidth(this.$refs.inner);
            this.effectiveWidth = `${width}px`;
        }
    }
};
</script>

<style lang="scss" scoped>
.complete-wrapper {
    position: relative;
    padding-right: 40px;
    .edit-button {
        position: absolute;
        //right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
</style>