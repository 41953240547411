<template>
    <div>
        <loader v-if="loading" />
        <div
            v-show="!loading"
            class="platform-map">
            <location-entry
                v-model="pinLocation"
                class="location-entry"
                :min-radius="minRadius"
                :max-radius="maxRadius"
                :locations="combinedLocations"
                :platform-config="platformConfig"
                :show-address="true"
                @input="handleLocationUpdate" />
            <map-locations
                v-model="locations"
                :types="types"
                :default-center="center" />
        </div>
    </div>
</template>

<script>
import LocationEntry from '@/components/globals/GeoSelector/LocationEntry';
import { gmapApi } from 'vue2-google-maps';
import Loader from '@/components/globals/Loader';
import EventBus from '@/event-bus';
import MapLocations from './MapLocations';
import { debounce } from 'lodash';
import { mapState } from 'vuex';
import { GEO, DMA, POSTAL_CODE } from '@/components/ad-deployment/store/constants';
import { formatToBuyerBridgeLocation } from '@/helpers/addressFormatters';
import { PLATFORM_PINTEREST } from '@/helpers/globals';

export default {
    components: {
        LocationEntry,
        Loader,
        MapLocations
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        minRadius: {
            type: [String, Number],
            default: 15
        },
        maxRadius: {
            type: [String, Number],
            default: 50
        },
        minHeight: {
            type: String,
            default: '450px'
        },
        platformConfig: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: true,
            lat: null,
            lng: null,
            hideDmas: false,
            hidePostalCodes: false,
            pinLocation: null,
            pinLocations: [],
            dmaLocations: [],
            postalCodeLocations: [],
            types: [GEO, DMA, POSTAL_CODE]
        };
    },
    computed: {
        ...mapState({
            dealerId: (state) => state.adDeployment.dealer_id,
            selectedChannels: (state) => state.adDeployment.platforms,
            isSimpleGeo: (state) => state.adDeployment.is_simple_geo,
            triggerMapRefresh: (state) => state.adDeployment.trigger_map_refresh,
            customMilePinterest: (state) => state.adDeployment.custom_mile_pinterest,
            customMilePinterestValue: (state) => state.adDeployment.custom_mile_pinterest_value,
        }),
        center() {
            return {
                lat: this.lat,
                lng: this.lng
            };
        },
        initialLocation() {
            return this.locations.find(location => location.type === GEO && !location.isCustom);
        },
        combinedLocations() {
            return [
                ...this.pinLocations,
                ...this.dmaLocations,
                ...this.postalCodeLocations
            ];
        },
        locations() {
            return [
                ...this.hideDmas ? [] : this.dmaLocations,
                ...this.hidePostalCodes ? [] : this.postalCodeLocations,
                ...this.pinLocations
            ].filter(Boolean);
        },
        dmaPlatformSelected() {
            return this.selectedChannels.some((channel) => {
                return this.platformConfig[channel].types.includes(DMA);
            });
        },
        postalCodePlatformSelected() {
            return this.selectedChannels.some((channel) => {
                return this.platformConfig[channel].types.includes(POSTAL_CODE);
            });
        },
        pinterestCustomLocation() {
            return {
                type: GEO,
                isCustom: true,
                coordinates: {
                    lat: this.lat,
                    lng: this.lng
                },
                radius: this.customMilePinterestValue,
                color: 'red'
            };
        },
        google: gmapApi
    },
    watch: {
        pinLocation(newPinLocation) {
            // Format from google to BB then add it to the store
            this.pinLocations = this.customMilePinterest ? [this.pinterestCustomLocation] : [];
            this.pinLocations.push(newPinLocation);
        },
    },
    async created() {
        EventBus.$on('save-locations', () => this.applyLocationsToStore());

        EventBus.$on('hide-location', ({ dma, postal_code }) => {
            this.hideDmas = dma;
            this.hidePostalCodes = postal_code;
        });

        try {
            const { data } = await this.$apiRepository.getDealer(this.dealerId);

            this.lat = parseFloat(data?.data?.latitude);
            this.lng = parseFloat(data?.data?.longitude);

            await this.initLocations(20);

            if (this.customMilePinterest) {
                this.pinLocations.push(this.pinterestCustomLocation);
            }
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    },
    methods: {
        applyLocationsToStore() {
            if (!this.isSimpleGeo) {
                return;
            }

            this.selectedChannels.forEach((channel) => {
                if (this.platformConfig[channel].types.includes(GEO)) {
                    if (channel === PLATFORM_PINTEREST) {
                        this.value[channel].locations = this.pinLocations.filter(location => {
                            return this.customMilePinterest ? location.isCustom : location;
                        });
                    } else {
                        this.value[channel].locations = this.pinLocations.filter(location => !location.isCustom);
                    }
                }

                if (this.platformConfig[channel].types.includes(DMA)) {
                    this.value[channel].locations = this.dmaLocations;
                }

                if (this.platformConfig[channel].types.includes(POSTAL_CODE)) {
                    this.value[channel].locations = this.postalCodeLocations;
                }
            });
            this.$emit('input', this.value);
        },
        async initLocations(radius) {
            try {
                const initLocation = {
                    radius,
                    geometry: {
                        lat: this.lat,
                        lng: this.lng
                    }
                };

                this.pinLocation = formatToBuyerBridgeLocation(initLocation, GEO);

                if (this.dmaPlatformSelected) {
                    await this.searchDma(radius, this.lat, this.lng);
                }

                if (this.postalCodePlatformSelected) {
                    await this.searchPostalCode(radius, this.lat, this.lng);
                }

                // Need when we go from simple -> customized geo
                this.applyLocationsToStore();
            } catch (err) {
                console.log(err);
            } finally {
                this.loading = false;
            }
        },
        handleLocationUpdate: debounce(async function({ radius }) {
            this.initialLocation.radius = radius;

            if (this.dmaPlatformSelected) {
                await this.searchDma(radius, this.lat, this.lng);
            }

            if (this.postalCodePlatformSelected) {
                await this.searchPostalCode(radius, this.lat, this.lng);
            }

        }, 500),
        async searchDma(radius = 20, lat, lng) {
            this.dmaLocations = await this.$apiRepository.searchDmaByGeo(radius, lat, lng);
        },
        async searchPostalCode(radius = 20, lat, lng) {
            this.postalCodeLocations = await this.$apiRepository.searchPostalCodes(radius, lat, lng);
        }
    }
};
</script>

<style lang="scss" scoped>
.platform-map {
    position: relative;
    z-index: 5;
    padding: 15px;
    background-color: #E6F6FD;
    border: 1px solid $loblolly;
    border-radius: 6px;
}

.map-container {
    border: 1px solid $gray;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
}
</style>

<style lang="scss">
.add-location {
    .v-input.v-text-field {
        margin: 0;
        padding: 0;

        & > .v-input__control > .v-input__slot {
            margin: 0;
            border-radius: 4px;
            border: 1px solid $loblolly;
            padding: 5px 10px;

            &::after, &::before {
                content: none;
            }

            input {
                &::placeholder {
                    color: $gray-dark;
                    font-size: 16px;
                    font-style: italic;
                }
            }
        }

        .v-input__append-inner {
            display: none;
        }

        .v-messages {
            min-height: 0;
        }
    }
}
</style>
